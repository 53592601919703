import { render, staticRenderFns } from "./AyudasEmprendedor.vue?vue&type=template&id=1ddb0d05&scoped=true&"
var script = {}
import style0 from "./AyudasEmprendedor.vue?vue&type=style&index=0&id=1ddb0d05&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddb0d05",
  null
  
)

export default component.exports